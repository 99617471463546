.registarion-body{
    position: absolute !important;
    width: 100% !important; 
    padding-top: 20vh;
    padding-bottom: 10vh;
} 

.blurrytext{  
    color: transparent !important;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important; 
    user-select: none;
} 

.orange-border-bottom{
    border-bottom: 1px solid #fd7e14 !important;
}

.purple-border-bottom{
    border-bottom: 1px solid #800080 !important;
}

#map{
    border: 1px solid red;
    margin: 0px;
    width: 100%;
    height: 300px !important;
    padding: 0px;
}
