
.p-profile{
    width: 150px;
    border-radius: 50%;
} 

.p-personal-tab .nav-link.active{ 
    color: #ff0000;
    background-color: #f8f9fa;
    position: relative; 
    border-bottom: 1px solid #89b3dd;
}
.p-category-tab .nav-link{
    background-color: #007bff;
    border-radius: 0 !important;
    border: transparent !important;
    
}
.p-category-tab .nav-link.active{
    background-color: violet !important;
}

.p-category-btn{
    max-width: 100vw;
    overflow: hidden;
}

.p-category-btn:hover{
    max-width: 100vw;
    overflow-x: scroll;
}

/* personal category bubbles */
.p-bubble:after {
	content: '';
	position: absolute;
	right: 16px;
	top: 20px;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-left-color: #d9dadb;
	border-right: 0;
	margin-top: -10px;
	margin-right: -10px;
}

/* personal category bubbles */
.p-bubble-top:after { 
    content: '';
    position: absolute;
    top: 0;
    left: 8%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #d9dadb;
    border-top: 0;
    margin-left: -10px;
    margin-top: -10px; 
}
  
.custom-fa{
    font-size: 1.4em !important;
}

/* appointment css */
.p-doctors-image{
    border-radius: 50%;
}

.p-card-hover:hover{
    background-color: #e6e6e6 !important;
    transform: scale(1.1);
    z-index: 1;
}

.p-app-count-container{
    position: relative !important;
    width: 50px;
}
.p-app-count-container .badge{
    left: -10px;
    font-size: 65%;
}

.p-app-date-container{
    position: relative !important;
    width:80px;
    height:83px;
    text-align: center !important;
}
.p-app-date-container .app-date-day{
    left: 2px;
    bottom: 62px;
    position: relative !important;
    color: #fff;
    font-size: 1.7em;
    font-weight: 500;
}
.p-app-date-container .app-date-time{
    left: 2px;
    bottom: 67px;
    position: relative !important;
    color: #fff;
    font-size: .5em;
    font-weight: 500;
}

.p-app-date-container .app-date-month{
    left: 3px;
    bottom: 68px;
    position: relative !important;
    color: #fff;
    font-size: .6em;
    font-weight: 500;
}
.p-app-hover:hover{
    background-color: #ebeef1 !important;
}

.p-credit-btn{
    background-color: #f8f9fa !important;
    border-color: #ccc;
}

.p-credit-active-btn{
    background-color: #e2e6ea !important;
    border-color: #ccc;
    color: #3275a7 !important;
}

/* hover upload image csss */ 

.gtc-profile{ 
    width: 150px;
    border-radius: 50%;
    height: 147px;
    overflow: hidden;   
}


.gtc-profile img{ 
    width: 150px;
    border-radius: 50%;
    height: 147px; 
}

 
.uploadLoading{
    text-align: center;
    position: absolute;
    width: 150px;
    border-radius: 50%;
    height: 150px;
    top: 9px;
    color: #fff;
    background: rgba(0, 0, 0, 0.438); 
}

.uploadLoading span{
    position: absolute; 
    top: 50px !important;
    left: 56px !important; 
}

.p-uploadhover{
    width: 130px;
    bottom: 35px;
    left: 10px;
    position: relative; 
    text-align: center;
    background: rgba(39, 39, 39, 0.445);
    padding: 8px;
}
 
.sample canvas{
    width: 1000px;
    height: 100px;
}

.slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.slide{
    opacity: 0;
    transition-duration: 1s ease;
}