.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.pointer {
	cursor: pointer !important;
}

.text-red {
	color: #ff0000 !important;
}

.gtc-text-header {
	font-size: 0.9em;
}

.cursor-drag {
	cursor: grab !important;
}

.gtc-scroll-limit {
	max-height: calc(90vh - 180px) !important;
	overflow-y: auto;
}

.gtc-scroll-limit::-webkit-scrollbar {
	width: 4px;
}

.gtc-scroll-limit::-webkit-scrollbar-thumb {
	background: #007bffa6;
}

.gtc-small {
	font-size: 0.7rem !important;
}

@media print {
	#printableContainer {
		border: none !important;
	}
	#printableRcptContainer {
		display: block;
		width: 100vh;
	}
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

/* online chat css */
/* width */
.onlineChatContainer::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.onlineChatContainer::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.onlineChatContainer::-webkit-scrollbar-thumb {
	background: #ccc;
}

/* Handle on hover */
.onlineChatContainer::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.bg-red {
	background-color: #ff0000 !important;
}

.text-red {
	color: #ff0000 !important;
}

.bg-cyan {
	background-color: #0f8496 !important;
}

.bg-green {
	background-color: #169a34 !important;
}

.bg-purple {
	background-color: #800080 !important;
}
.border-purple {
	border-color: #800080 !important;
}

.pagenotfound {
	opacity: 3.5 !important;
	background-color: #0b2d48 !important;
}

.header-label {
	font-size: 14px;
}

.show > .dropdown-menu {
	display: block !important;
	position: absolute !important;
}

.btn {
	border-radius: none !important;
}

.gtc-text {
	color: #67727a !important;
}

.gtc-bg-purple {
	background-color: #3275a7 !important;
}

.gtc-text-purple {
	color: #3275a7 !important;
}

.gtc-darkblue {
	color: #3275a7 !important;
}

.gtc-border-purple {
	border: 1px solid #3275a7 !important;
}

.gtc-active-btn {
	border-radius: 0 !important;
	background-color: #e2e6ea !important;
	border-color: #e2e6ea !important;
	color: #3275a7 !important;
}

.react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	width: 100% !important;
	border: 0;
}

.bg-teal {
	background-color: #20c997 !important;
}

.border-teal {
	border: 1px solid #20c997 !important;
}

.bg-light-2 {
	background-color: #eff4f8;
}
.border-orange {
	border-color: #fd7e14 !important;
}

.bg-orange {
	background-color: #fd7e14 !important;
}
.gtc-text-orange {
	color: #fd7e14 !important;
}

.gtc-text-purple {
	color: #800080 !important;
}

.Toastify__toast--default {
	border-radius: 0.2em !important;
	background: #002f65e0 !important;
	color: #fff !important;
}

.Toastify__close-button--default {
	color: #f6f6f6 !important;
	opacity: 1 !important;
}

.gtc-uppercase {
	text-transform: uppercase !important;
}
.gtc-lowercase {
	text-transform: lowercase !important;
}
.gtc-capitalize {
	text-transform: capitalize !important;
}
.gtc-bold {
	font-weight: bold !important;
}

.gtc-float-right {
	float: right !important;
}
.gtc-clearfix {
	clear: both !important;
}

.gtc-text-danger {
	color: #dc3545 !important;
}

.gtc-text-primary {
	color: #007bff !important;
}
.gtc-bg-light {
	background-color: #edf0f385 !important;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast-container {
		width: 80% !important;
		padding: 0 !important;
		left: 0 !important;
		margin: 7% !important;
	}
	.Toastify__toast {
		margin-bottom: 0.5rem !important;
	}
}

/* movingtext on appbar */
.movingtext {
	width: 50px;
	overflow: hidden;
	position: relative;
}
.movingtext span:hover {
	animation-play-state: paused;
}
.movingtext span {
	position: absolute;
	width: 300px;
	height: 100px;
	margin: 0;
	line-height: 25px;
	/* Starting position */
	-moz-transform: translateX(50%);
	-webkit-transform: translateX(50%);
	transform: translateX(50%);
	/* Apply animation to this element */
	-moz-animation: scrolltext 10s linear infinite;
	-webkit-animation: scrolltext 10s linear infinite;
	animation: scrolltext 10s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes scrolltext {
	0% {
		-moz-transform: translateX(100%);
	}
	100% {
		-moz-transform: translateX(-100%);
	}
}
@-webkit-keyframes scrolltext {
	0% {
		-webkit-transform: translateX(100%);
	}
	100% {
		-webkit-transform: translateX(-100%);
	}
}
@keyframes scrolltext {
	0% {
		-moz-transform: translateX(100%); /* Firefox bug fix */
		-webkit-transform: translateX(100%); /* Firefox bug fix */
		transform: translateX(100%);
	}
	100% {
		-moz-transform: translateX(-100%); /* Firefox bug fix */
		-webkit-transform: translateX(-100%); /* Firefox bug fix */
		transform: translateX(-100%);
	}
}

.purple-header {
	background-color: #3f51b5 !important;
	color: #fff !important;
}

.gtc-striketext {
	text-decoration: line-through;
}

.isrequired::after {
	content: "*";
	color: red;
	font-size: 1rem;
}

.gtc-formlabel {
	font-size: 0.7rem !important;
	color: #6c757d !important;
	text-transform: uppercase;
}

.gtc-custom-picker .react-datepicker-popper {
	z-index: 500 !important;
}

.gtc-textfield-noborder .MuiOutlinedInput-notchedOutline {
	border-color: #e6e6e6 !important;
}

.docdonascoPageBreak .breakerpage {
	page-break-after: always !important ;
}

@media print {
	/* .gtc-printable-docdonascopaper{  
    padding: 20px !important;
    margin: 10px !important; 
    border:1px dotted #ccc !important;     
  }   */

	.gtc-printable-landscape {
		width: 50%;
		padding: 3px;
		border-right: 1px dotted #ccc !important;
		border-bottom: 1px dotted #ccc !important;
	}
	/*   
  @page {
    size: 210mm 297mm  !important; 
    margin: 0mm 0mm 0mm 0mm !important;
  }  */
}

.gtc-table-borderless .MuiTableCell-root {
	border-bottom: none !important;
	padding: 10px;
}

.noborder-select {
	border: none !important;
}

.noborder-select select {
	font-size: 13px !important;
}

.noborder-select .MuiInput-underline::before {
	border: none !important;
}

.gtc-appbar .appbarLink a {
	color: #b9b9b9;
	text-decoration: none;
	background-color: transparent;
}

.gtcmap #map {
	border: none;
	height: calc(100vh - 200px) !important;
}

#instructions {
	max-height: calc(100vh - 200px) !important;
	overflow-y: auto;
}

.duration {
	font-size: 2em;
}

.gtc-viewimage img {
	transform: scale(0.5) !important;
}

#draggable-handle {
	cursor: move;
}

.animatedBounce {
	-webkit-animation: xanimatexx 4s; /* Chrome, Safari, Opera */
	animation: xanimatexx 4s;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes xanimatexx {
	50% {
		padding-bottom: 300px;
	}
}
/* Standard syntax */
@keyframes xanimatexx {
	50% {
		padding-bottom: 300px;
	}
}

.react-transform-component {
	height: 90vh !important;
	width: calc(100vw - 50px) !important;
	overflow: hidden;
}

.react-transform-component img {
	width: 40vw !important;
}

.vcallContainer video {
	max-width: 100% !important;
	max-height: 300px !important;
	border-radius: 5px;
	border: 1px solid red;
}

.vcallContainerMobile video {
	max-width: calc(85vw - 100px) !important;
	max-height: 250px !important;
	border-radius: 5px !important;
	border: 1px solid blue;
}

.myvideotagOnWeb video {
	position: absolute !important;
	bottom: 0;
	right: 0;
	margin: 10px;
	max-width: 200px;
	max-height: 200px;
	border-radius: 10px;
	border: 2px solid #2196f3;
}

.myvideotagOnWeb .myvtagisloading {
	position: absolute !important;
	bottom: 0;
	right: 0;
	margin: 10px;
	max-width: 200px;
	max-height: 200px;
	border-radius: 10px;
}

.myClientVideotagOnWeb video {
	width: calc(100vw - 50px);
	height: calc(100vh - 195px);
}

.myClientVideotagOnWeb .myclientvtagisloading {
	position: absolute;
	top: 50%;
	left: 45vw;
}

@media only screen and (max-width: 900px) {
	.myvideotagOnWeb video {
		position: absolute !important;
		top: 0;
		right: 0;
		margin: 10px;
		max-width: 100px;
		max-height: 100px;
		border-radius: 10px;
		border: 2px solid #2196f3;
	}

	.myClientVideotagOnWeb .myclientvtagisloading {
		position: absolute;
		top: 50%;
		left: 30vw;
	}

	.myvideotagOnWeb .myvtagisloading {
		position: absolute !important;
		top: 0;
		right: 0;
		margin: 10px;
		max-width: 100px;
		max-height: 100px;
		border-radius: 10px;
	}
}

.update-profile .cropper-container {
	height: calc(100vh - 130px) !important;
	width: 100% !important;
	/* overflow: hidden !important; */
}

.convo-container {
	width: 350px;
	height: calc(80vh - 120px);
	overflow-x: hidden;
	overflow-y: scroll;
}
